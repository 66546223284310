@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

* {
  font-family: "Open Sans", sans-serif;
}

table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.95em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

table thead tr th {
  background-color: white;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  box-sizing: border-box;
}

table th,
table td {
  padding: 5px;
}

tbody tr:hover td {
  background-color: rgb(185, 178, 178);
  transition: all 0.2s ease-out;
}

tbody td:nth-child(2) {
  background-color: #f8cdcb;
}
tbody td:nth-child(3) {
  background-color: #ecd5a3;
}
tbody td:nth-child(4) {
  background-color: #f1eb97;
}
tbody td:nth-child(5) {
  background-color: #d8eccc;
}
tbody td:nth-child(6) {
  background-color: #bcd6f7;
}
tbody td:nth-child(7) {
  background-color: #caa7da;
}
tbody td:nth-child(8) {
  background-color: #c286b8;
}
tbody td:nth-child(9) {
  background-color: #f3cdf7;
}
tbody td:nth-child(10) {
  background-color: #d1f8f8;
}
tbody td:nth-child(11) {
  background-color: #ffffff;
}
tbody td:nth-child(12) {
  background-color: #d8eccc;
}
tbody td:nth-child(13) {
  background-color: #fcf8df;
}

table thead th {
  top: 0;
  position: sticky;
  z-index: 11;
}

table tbody tr {
  border-bottom: 1px solid #dddddd;
}

table tbody {
  font-weight: normal;
}

tr {
  padding: 20px 0;
  width: 100%;
}

.vimeoButton {
  height: 100%;
  width: 100%;
}

.email-cell {
  position: relative;
  cursor: default;
  padding-right: 30px;
}

.copy-icon .mail-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  cursor: pointer;
  color: #333;
  transition: opacity 0.2s ease;
  z-index: 1;
}

.email-cell:hover .copy-icon .mail-icon {
  opacity: 1;
}

td.email-cell {
  position: relative;
  overflow: visible;
}

.email-cell {
  position: relative;
  cursor: default;
  padding-right: 60px; /* Increased to accommodate both icons */
}

.icon-container {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.email-cell:hover .icon-container {
  opacity: 1;
}

.mail-icon,
.copy-icon {
  cursor: pointer;
  color: #333;
  transition: color 0.2s ease;
}

.mail-icon:hover,
.copy-icon:hover {
  color: #000;
}
