.assignment-wrapper {
  border: 1px dashed lightgray;
  padding: 2em;
  border-radius: 1em;
  margin-top: 2em;
}

.assignment-wrapper-title {
  width: min-content !important;
  margin-top: -2em !important;
  margin-bottom: 1em !important;
  background-color: white !important;
  white-space: nowrap !important;
}

.error {
  color: red;
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: black;
}
.blue-link {
  text-decoration: none;
  cursor: pointer;
  color: blue;
}
.comment-bubble {
  background: #eeeeee;
  border-radius: 1em;
  padding: 1em;
  margin: 1em 0em;
}

.logo {
  padding: 0.5em 0em;
  max-width: 10em;
}

.nav {
  min-height: 100px;
  border-bottom: 1px solid grey;

  width: 100%;
}

.NavBar {
  padding-top: 25px;
}
