#sidebar-wrapper{
    min-height: 100vh !important;
    margin-left: -1rem;
    width:12%;
}

#sidebar-wrapper .sidebar-heading {
    /* padding: 0.875rem 1.25rem; */
    font-size: 1.2rem;

} 

#page-content-wrapper {
    width: 88%;
}