.btn-info {
    background-color: #9be3f1;
    border-color: #000
}

.btn-warning {
    background-color: #ffe79d;
    border-color: #000
}

.btn {
    border-radius: .50rem;
}

.btn-info:hover, .btn-warning:hover {
    border-color: #000;
}
