.demo-wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.demo-editor {
  padding: 10px;
  min-height: 150px;
  background-color: #f8f9fa;
}

.rdw-editor-toolbar {
  border-bottom: 1px solid #ccc;
  padding: 6px;
  background-color: #f8f9fa;
}

/* Remove the focus effect since we're keeping it white */

.demo-editor pre {
  background-color: #f6f8fa;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 16px;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
  font-size: 14px;
  line-height: 1.45;
  overflow: auto;
  margin: 8px 0;
}

/* Style for code blocks in displayed comments */
.comment-bubble pre {
  background-color: #2d2d2d; /* Dark grey background */
  color: #e6e6e6; /* Light grey text */
  border: 1px solid #444;
  border-radius: 6px;
  padding: 16px;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
  font-size: 14px;
  line-height: 1.45;
  overflow: auto;
  margin: 8px 0;
}

/* Ensure code blocks are preserved when converting to HTML */
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
