.sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    min-width: none !important;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    flex-wrap: wrap;
    flex-basis: 100%;
    /* padding: 48px 0 0; */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.col-2 {
    display: flex;
}

.content {
    min-height: 100vh !important;
}

.profile-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.profile {
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 40px 0 0;
}

.leaaaY {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
}

.cbImHq {
    flex-basis: 100%;
}

.item-content {
    margin-top: 20em;
}

.name-card {
    padding: 20px; 
}

.convo-card {
    padding: 20px;
}